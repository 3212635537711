import { Box, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import type { ReactNode } from 'react'
import SelectDate from './SelectDate'
import SelectSlot from './SelectSlot'
import { useFeatureFlag } from '~/utils/useFeatureFlag'
import {
  AppointmentTypeMapping,
  AvailablePhysicianSlot,
  VisitReason,
} from '~/api/AppointmentService'
import { format } from 'date-fns'
import { useAppointmentTypeByReason, useGetSymptomVisitType } from '~/api/ScheduleService'
import { useGetPerson } from '~/api/PersonService'
import { SymptomItem } from './PrimaryConcern'
import BookAppointmentByDayTab from './BookAppointmentByDayTab'
import BookAppointmentByProviderTab from './BookAppointmentByprovider'

type BookAppointmentTabTypes = 'byDay' | 'byProvider'

const DATE_STR_FORMAT = 'MMM D, YYYY'

type BookAppointmentProps = {
  patientId: number
  personId: number
  visitReason: VisitReason | null
  setVisitReason: (value: VisitReason | null) => void
  now: Date
  date: Date
  setDate: (value: Date) => void
  slot: AvailablePhysicianSlot | null
  setSlot: (value: AvailablePhysicianSlot) => void
  setIsLoading: (e: boolean) => void
  isLoading: boolean
  symptomsSelected: SymptomItem[]
  otherSymptomsSelected: string[]
}

interface TabPanelProps {
  children: ReactNode
  value: BookAppointmentTabTypes
  selectedTab: BookAppointmentTabTypes
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, selectedTab } = props
  const visible = value === selectedTab

  return (
    <div
      role="tabpanel"
      hidden={!visible}
      id={`book-appointment-tabpanel-${value}`}
      aria-labelledby={`book-appointment-tab-${value}`}
    >
      {visible && <Box>{children}</Box>}
    </div>
  )
}

const Mapping = {
  Video: 'video',
  'Video-New': 'videoNew',
  'Annual Wellness': 'annualWellness',
  'Health Guide Consult': 'healthGuideConsult',
  'Behavioral Health': 'behavioralHealth',
  Focused: 'focused',
}

const BookAppointment: FC<BookAppointmentProps> = ({
  patientId,
  personId,
  visitReason,
  setVisitReason,
  date,
  setDate,
  now,
  slot,
  setSlot,
  setIsLoading,
  isLoading,
  symptomsSelected,
  otherSymptomsSelected,
}) => {
  const isTabsDuringBookingActive = useFeatureFlag('appointment.enableTabsDuringBooking')
  const isBreakTheGlassActive = useFeatureFlag('appointment.enableBreakTheGlass')
  const { result: appointmentTypeByReason } = useAppointmentTypeByReason()
  const { data: person } = useGetPerson({ personId: personId! })
  const { result: symptomVisitType } = useGetSymptomVisitType({
    symptom_ids: symptomsSelected.map(({ id }) => id),
    other_symptoms: otherSymptomsSelected.length ? otherSymptomsSelected.join(', ') : undefined,
    patient_id: patientId,
  })
  const [tab, setTab] = useState<BookAppointmentTabTypes>('byDay')
  const date_of_last_awv =
    person && person['primaryCareProgramInfo'] && person['primaryCareProgramInfo']['dateOfLastAwv']
  const date_of_last_awv_as_date = new Date(date_of_last_awv)
  const today = new Date()
  const due_beginning =
    visitReason === 'annualWellnessVisitEstablished' && date_of_last_awv
      ? date_of_last_awv_as_date.setFullYear(date_of_last_awv_as_date.getFullYear() + 1)
      : null
  // If the selected visit type is Medical issue (meaning visitReason === 'video'),
  // only then we need to consider the result of symptomVisitType mapping to determine Video or Focused
  // based on the symptom selected
  useEffect(() => {
    if (symptomVisitType && visitReason && ['video', 'focused'].includes(visitReason)) {
      setVisitReason(Mapping[symptomVisitType.reason ? symptomVisitType.reason : 'Video'])
    }
  }, [symptomVisitType, visitReason])

  return (
    <>
      {visitReason ? (
        <Box m={2}>
          <Typography fontSize={16} fontWeight={400} sx={{ color: '#071F3E' }}>
            {'Appt length: '}
            {appointmentTypeByReason[AppointmentTypeMapping[visitReason]]?.duration} {'min'}
          </Typography>
          <Typography fontSize={16} fontWeight={400} sx={{ color: '#071F3E' }}>
            {(!date_of_last_awv && visitReason === 'annualWellnessVisitEstablished') ||
            (due_beginning && new Date(due_beginning) < today)
              ? 'Overdue'
              : due_beginning
              ? `Due beginning: ${format(due_beginning, DATE_STR_FORMAT)}`
              : null}
          </Typography>
        </Box>
      ) : null}
      {visitReason ? (
        isTabsDuringBookingActive ? (
          <Box m={2}>
            <>
              <ToggleButtonGroup
                color="primary"
                value={tab}
                exclusive
                onChange={(e, nextTab) => {
                  if (!nextTab) {
                    return
                  }
                  setTab(nextTab)
                }}
                size="small"
                aria-label="Status"
                sx={{
                  '.MuiToggleButton-root.MuiToggleButtonGroup-grouped': {
                    padding: '7px 20px',
                    textTransform: 'capitalize',
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                  '.MuiToggleButton-root.Mui-selected': {
                    backgroundColor: '#f5f5f5',
                    color: '#021A39',
                  },
                }}
              >
                <ToggleButton value="byDay">By Day</ToggleButton>
                {isBreakTheGlassActive && (
                  <ToggleButton value="byProvider">By Provider</ToggleButton>
                )}
              </ToggleButtonGroup>
            </>
            <TabPanel value="byDay" selectedTab={tab}>
              <BookAppointmentByDayTab
                patientId={patientId}
                visitReason={visitReason}
                date={date}
                setDate={setDate}
                now={now}
                slot={slot}
                setSlot={setSlot}
                setIsLoading={setIsLoading}
              />
            </TabPanel>
            <TabPanel value="byProvider" selectedTab={tab}>
              <BookAppointmentByProviderTab />
            </TabPanel>
          </Box>
        ) : (
          <Box m={2}>
            <SelectDate date={date} setDate={setDate} now={now} />

            <SelectSlot
              date={date}
              patientId={patientId}
              visitReason={visitReason}
              slot={slot}
              setSlot={setSlot}
              setIsLoading={setIsLoading}
            />
          </Box>
        )
      ) : !isLoading ? (
        <Box m={2}>
          <Typography>{'Patient is not eligible to book appointment.'}</Typography>
        </Box>
      ) : null}
    </>
  )
}

export default BookAppointment
