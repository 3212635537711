import { Autocomplete, Box, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

import Loader from '~/components/Loader'
import { useMutation } from '~/utils/useMutation'
import * as ProviderService from '~/api/ProviderService'

const BookAppointmentByProviderTab = () => {
  const [physicianFilter, setPhysicianFilter] = useState({})
  const [showLoader, setShowLoader] = useState(false)
  const [selectedPhysician, setSelectedPhysician] = useState('')

  const { loading: loadingPhysicians, handler: loadPhysicians } = useMutation(async () => {
    const result = await ProviderService.getPhysicians()
    const data = result.filter(physician => physician.calendarUrl !== null)
    const physicianData: Record<string, ProviderService.Physician> = {}
    data.map(physician => {
      console.log(physician)
      const physicianName = physician.firstName + ' ' + physician.lastName + ', ' + physician.title
      physicianData[physicianName] = physician
    })
    setPhysicianFilter(physicianData)
    return result
  })

  useEffect(() => {
    loadPhysicians()
  }, [])

  const onPhysicianChange = async (selected: string) => {
    setShowLoader(true)
    if (selected && physicianFilter[selected]) {
      setSelectedPhysician(selected)
    } else {
      setSelectedPhysician('')
    }
    setShowLoader(false)
  }

  return (
    <>
      {(loadingPhysicians || showLoader) && <Loader />}
      <Box>
        <Box sx={{ flexGrow: 1 }} paddingTop={4}>
          <Autocomplete<string>
            size="small"
            options={Object.keys(physicianFilter).sort()}
            value={selectedPhysician}
            renderInput={params => <TextField {...params} label="Provider" />}
            onChange={(_, selected) => {
              onPhysicianChange(selected || '')
            }}
            style={{ width: 280 }}
          />
        </Box>
      </Box>
    </>
  )
}

export default BookAppointmentByProviderTab
